import { useAccount, useMsal } from '@azure/msal-react';
import * as React from 'react';
import { useState, useEffect, useRef, useMemo } from 'react';
import { useGraphFetchClient, useSpFetchClient, useSP } from '../../services/auth.service';
import { SiteGlobalConfig } from '../../site.config';
import { IToolbarCommand, Toolbar } from '../Toolbar/Toolbar';
import { Link } from '@reach/router';
import { FabricIcon } from '../FabricIcon/FabricIcon';

import * as styles from './PortalRequestList.module.scss';
import { TextField } from '../WebBlocks/WebForm/TextField/TextField';

import '@pnp/sp/lists';
import '@pnp/sp/items';
import '@pnp/sp/attachments';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import { navigate } from 'gatsby';

export interface IPortalRequestListProps{
    onSelectionChange: (newSelection: ISelectedRequestItem[]) => void;
    toolbarCommands: IToolbarCommand[];
    openModalByKey: (key: string, selectedItems: ISelectedRequestItem[]) => void;
}

export interface ISelectedRequestItem{
    Id: string|number;
    Title: string;
    Currentstate?: string;
    MasterworkitemID?: string;
}

export interface IListColumn{
    key: string;
    title: string;
    fieldName: string;
    onRenderColumn?: (value: any, obj: any) => any;
    canSort?: boolean;
}

const getFieldValue = (fieldName: string, obj: any) => {
    if(fieldName && obj){
        const fieldNameArr = fieldName.split("/");

        return fieldNameArr.reduce((acc, curr) => {
            const nextVal = acc ? acc[curr] : acc;
            return nextVal;
         } ,obj);
    }
    else {
        return "";
    }
}

export const PortalRequestList = (props: IPortalRequestListProps) => {
    const { onSelectionChange, toolbarCommands, openModalByKey } = props;
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const fetchClient = useGraphFetchClient(instance);
    const [listItems, setListItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasErrored, setHasErrored] = useState(false);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [nextPageUrl, setNextPageUrl] = useState<string>();
    const [pageUrlHistory, setPageUrlHistory] = useState<string[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [viewMode, setViewMode] = useState<number>(0);
    const [orderByField, setOrderByField] = useState<string>("Created");
    const [orderByDirection, setOrderByDirection] = useState<string>("desc");

    const { GRAPH_ROOT_URL, CMS_SHAREPOINT_SITE_ID, CMS_REQUESTS_LIST_ID, CMS_SHAREPOINT_SITE_URL, CMS_FORMS_LIST_ID, SP_TENANT_ROOT_URL } = SiteGlobalConfig;
    const spSite = useSP(CMS_SHAREPOINT_SITE_URL);

    const dummyAnchor = useRef();

    useEffect(() => {
        // getRequests();
        setPageUrlHistory([]);
        const firstPageUri = `${ GRAPH_ROOT_URL }/sites/${ CMS_SHAREPOINT_SITE_ID }/lists/${ CMS_REQUESTS_LIST_ID }/items?$expand=fields($select=Id,Title,Requesttype,Currentstate,Budgetbaseline,MP,Party,MasterworkitemID,ContentType,attachments,Created,Response_x0020_URL,Response_x0020_date,FormLookupId,CanEdit,CanHold,CanWithdraw,CanPublicRelease,CanUpdateResponse)&$top=25&$orderby=fields/${orderByField} ${orderByDirection}`;
        getRequestsPage(firstPageUri);
    },[orderByField, orderByDirection]);

    useEffect(() => {
        setSelectedIds([]);
    },[listItems]);

    useEffect(() => {
        console.log(spSite);
    },[spSite]);

    useEffect(() => {
        if(searchText.length > 2){
            const searchUri = `${ GRAPH_ROOT_URL }/sites/${ CMS_SHAREPOINT_SITE_ID }/lists/${ CMS_REQUESTS_LIST_ID }/items?$expand=fields($select=Id,Title,Requesttype,Currentstate,Budgetbaseline,MP,Party,MasterworkitemID,ContentType,attachments,Created,Response_x0020_URL,FormLookupId)&$filter=contains(fields/Title, '${searchText}')&$top=25&$orderby=fields/Created desc`;
            getRequestsPage(searchUri);
        }
    },[searchText]);

    useEffect(() => {
        onSelectionChange(mapSelectedIds(selectedIds));
    },[selectedIds]);

    const mapSelectedIds = (ids: number[]) => {
        return ids.map(id => {
            const item = listItems.filter(v => v.id == id);

            return {
                Id: id,
                MasterworkitemID: item[0].fields.MasterWorkItemID,
                Title: item[0].fields.Title,
                Currentstate: item[0].fields.Currentstate
            }
        })
    }

    // const getRequests = async () => {
    //     const uri = `${ GRAPH_ROOT_URL }/sites/${ CMS_SHAREPOINT_SITE_ID }/lists/${ CMS_REQUESTS_LIST_ID }/items?$expand=fields($select=Title,Requesttype,Currentstate,Budgetbaseline,MP,Party,MasterworkitemID,ContentType,attachments,Response_x0020_URL)&$top=25`;
    //     const headers = new Headers({ accept: "application/json", "content-type": "application/json" });
    //     const listItemRes = await fetchClient.fetch(uri, { headers });

    //     if(listItemRes.ok){
    //         const { value } = await listItemRes.json();
    //         console.log(value);
    //         setListItems(value);
    //     }
    // }
    
    const getRequestsPage = async (uri: string, prev: boolean = false) => {
        setIsLoading(true);
        const headers = {
            "accept": "application/json", 
            "content-type": "application/json",
            "prefer": "HonorNonIndexedQueriesWarningMayFailRandomly"
        };

        const listItemRes = await fetchClient.fetch(uri, { headers });
    
        if(listItemRes.ok){
            const { value, "@odata.nextLink": nextUri } = await listItemRes.json();


            setPageUrlHistory((currentPageHistory) => {
                return prev ? [...currentPageHistory.slice(0, currentPageHistory.length -1)] : [...currentPageHistory, uri];
            });
            setNextPageUrl(nextUri);
            setListItems(value);
        }
        setIsLoading(false);
    }

    const navigateToForm = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, formId: number|string) => {
        event.stopPropagation();
        event.preventDefault();

        const formUri = `${ GRAPH_ROOT_URL }/sites/${ CMS_SHAREPOINT_SITE_ID }/lists/${ CMS_FORMS_LIST_ID }/items/${formId}?$select=contentType`;

        const headers = {
            "accept": "application/json", 
            "content-type": "application/json",
            "prefer": "HonorNonIndexedQueriesWarningMayFailRandomly"
        };

        const formRes = await fetchClient.fetch(formUri, { headers });

        const formData = formRes.ok ? await formRes.json() : null;

        console.log(formData);

        if(formData && formData.contentType && formData.contentType.id){
            switch(formData.contentType.id){
                // Policy costing request form
                case("0x0100E012494E3DA5B84E9A991F4EF4AC07650100BD3EFA6F99C325439BE1EF40BEB8A861"): {
                    window.open(`/secure/policycostingform/${formId}`, "_blank");
                    // navigate(`/secure/policycostingform/${formId}`);
                    break;
                }

                case("0x0100E012494E3DA5B84E9A991F4EF4AC07650200172DB8CFFC7E8D4B85D0653672A9318E"): {
                    window.open(`/secure/adviceform/${formId}`, "_blank");
                    navigate(`/secure/adviceform/${formId}`);
                }
            }
        }
    }

    const columns: IListColumn[] = [
        {
            key: "title",
            canSort: true,
            title: "Request name",
            fieldName: 'Title',
            onRenderColumn: (value, obj) => {
                return obj.FormLookupId ? (
                        <td>
                            <a onClick={ (event) => navigateToForm(event, obj.FormLookupId)}>{ value }</a>
                        </td>
                    ) : (
                        <td>
                            <span>{ value }</span>
                        </td>
                    )
            }
        },
        {
            key: "contentType",
            title: "Content type",
            fieldName: 'Requesttype',
            canSort: true,
        },
        {
            key: "status",
            title: "Status",
            fieldName: 'Currentstate',
            canSort: true,
        },
        {
            key: "budgetBaseline",
            title: "Budget baseline",
            fieldName: 'Budgetbaseline',
            canSort: true,
        },
        {
            key: 'mp',
            title: "MP",
            fieldName: 'MP/Label',
            canSort: false
        },
        {
            key: 'party',
            title: "Party",
            fieldName: 'Party/Label',
            canSort: false,
        },
        // {
        //     key: 'requestedDate',
        //     title: "Date requested",
        //     fieldName: null
        // },
        {
            key: 'responseDate',
            title: "Response date",
            fieldName: "Response_x0020_date",
            canSort: true,
            onRenderColumn: (val) => {
                return val ? (
                    <td>{ new Date(val).toLocaleDateString("en-au") }</td>
                ) : (
                    <td>
                        <span></span>
                    </td>
                )
            }
        },
        {
            key: 'link',
            title: 'Response',
            fieldName: 'MasterWorkItemID',
            canSort: true,
            onRenderColumn: (val, obj) => (
                <td className={ styles.itemResponseLinkContainer }>
                    {
                        obj.Response_x0020_URL && (
                            <Link className={ styles.itemResponseLink } to={ `/secure/response/${val}` } >Open</Link>
                        )
                    }
                    {
                        obj.Attachments && (
                            <a className={ styles.itemResponseLink } onClick={ () => onDownloadPdfClick(obj.id) } >Open</a>
                        )
                    }
                </td>
            )
        }
    ]

    const onDownloadPdfClick = async (listItemId: string) => {
        console.log(spSite);
        const files = await spSite.lists.getById(CMS_REQUESTS_LIST_ID).items.getById(parseInt(listItemId)).attachmentFiles();
        
        files.forEach((file) => {
            const a: any = dummyAnchor.current;
            a.href = `${SP_TENANT_ROOT_URL}${file.ServerRelativeUrl}`;
            a.download = file.FileName;

            a.click();
        });
    }

    const onRowClick = (rowListItemId: number, checked: boolean, replace?: boolean) => {
        const currentChecked = selectedIds.filter(v => v!== rowListItemId);
        const newChecked = checked ? [...currentChecked, rowListItemId] : currentChecked;

        setSelectedIds(replace === true ? [rowListItemId] : newChecked);
    }

    return (
        <div className={ styles.listContainer } >
            <h1 className={ styles.listHeading }>My requests</h1>
            <Toolbar commands={ toolbarCommands } />
            <div className={ styles.searchContainer }>

                
                
            </div>
            <div className={ styles.filterRow }>
                <input type="text" placeholder='Search' onChange={ (event) => setSearchText(event.target.value) } className={ styles.searchInput } />
                <div className={ styles.viewSelectorContainer }>
                    <span>Request view</span>
                    <div className={ styles.viewSelector }>
                        <a onClick={ () => setViewMode(1) } className={ viewMode === 1 ? styles.viewSelector_selected : null } >New (beta)</a>
                        <a onClick={ () => setViewMode(0) } className={ viewMode === 0 ? styles.viewSelector_selected : null } >List</a>
                    </div>
                </div>
                <div className={ styles.filterContainer }>
                    <div className={ styles.filterInputContainer }>
                        <label htmlFor="orderbyselect" >Order by</label>
                        <select id='orderbyselect' onChange={ (ev) => setOrderByField(ev.target.value) } value={ orderByField }>
                            <option>Created</option>
                            {
                                columns.filter(v => v.canSort).map((v) => {
                                    return (
                                        <option value={ v.fieldName }>{ v.title }</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className={ styles.viewSelectorContainer }>
                        <span>Direction</span>
                        <div className={ styles.viewSelector }>
                            <a onClick={ () => setOrderByDirection("asc") } className={ orderByDirection === "asc" ? styles.viewSelector_selected : null } >Ascending</a>
                            <a onClick={ () => setOrderByDirection("desc") } className={ orderByDirection === "desc" ? styles.viewSelector_selected : null } >Descending</a>
                        </div>
                    </div>
                </div>
            </div>
            {
                viewMode === 1 ? (
                    <>
                    {
                        isLoading ? (
                            <LoadingIndicator fontSize='6px' />
                        ) : (
                            <NewRequestList items={ listItems } columns={ columns } onRowClick={ onRowClick } selectedIds={ selectedIds } navigateToForm={ navigateToForm } openModalByKey={ (key: string, selectedIds: any[]) => openModalByKey(key, mapSelectedIds(selectedIds)) } />
                        )

                    }
                    </>
                ) : (
                    <table className={ styles.listTable }>
                        <thead>
                            <tr>
                                <th></th>
                                {
                                    columns.map((c) => {
                                        return (
                                            <th>{ c.title }</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isLoading ? (
                                    <tr>
                                        <td colSpan="100%">
                                            <LoadingIndicator fontSize='6px' />
                                        </td>
                                    </tr>
                                ) : (
                                    listItems.map((item) => {
                                        const isSelected = selectedIds.indexOf(item.id) >= 0;
            
                                        return (
                                            <tr onClick={ () => onRowClick(item.id, !isSelected) }>
                                                <td><input type="checkbox" checked={ isSelected } onChange={ (_) => onRowClick(item.id, !isSelected) } className={ styles.tableCheckbox }/></td>
                                                {
                                                    columns.map(c => {
                                                        const val = getFieldValue(c.fieldName, item.fields);
                                                        
                                                        return c.onRenderColumn ? c.onRenderColumn(val, item.fields) : (
                                                            <td>{ val || "" }</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                )
                            }
                        </tbody>
                    </table>
                )
            }
            

            <div className={ styles.tableNavigationContainer }>
                <a onClick={ () => !isLoading && pageUrlHistory.length > 1 ? getRequestsPage(pageUrlHistory[pageUrlHistory.length - 2],  true) : null } className={ pageUrlHistory.length > 1 ? "" : styles.disabledLink }>Previous</a>
                
                <span>Page { pageUrlHistory.length }</span>

                <a onClick={ () => !isLoading && nextPageUrl ? getRequestsPage(nextPageUrl) : null } className={ nextPageUrl ? "" : styles.disabledLink }>Next</a>
            </div>

            <a ref={ dummyAnchor } style={{ display: "none" }} target="_blank"></a>
        </div>
    )
}

export const NewRequestList = (props: {items:any[], columns: IListColumn[], onRowClick: (rowId: any, checked: boolean, replace?: boolean) => void, selectedIds: any[], navigateToForm: (e: any, formId: number) => void, openModalByKey: (key: string, selectedIds: any[]) => void }) => {
    const { items, columns, onRowClick, selectedIds, navigateToForm, openModalByKey } = props;

    return (
        items.map((item) => {
            const { Title, Requesttype, Currentstate, Budgetbaseline, MP, Party, Created, Response_x0020_date, Response_x0020_URL, CanEdit, CanHold, CanWithdraw, CanPublicRelease, CanUpdateResponse, FormLookupId } = item.fields;
            const Id = item.id;
            const isSelected = selectedIds.indexOf(item.id) >= 0;
            
            return (
                <div className={ styles.new_requestListItemContainer } data-is-selected={ isSelected }>
                    <div className={ styles.new_checkboxContainer }>
                        <input type='checkbox' checked={ isSelected } onClick={ (e) => { e.preventDefault(); onRowClick(Id, !isSelected) }} />
                    </div>
                    <div className={ styles.new_requestLhContainer } onClick={ () => onRowClick(Id, !isSelected, true) }>
                        <div className={ styles.new_requestTitleContainer}>
                            <span className={ styles.new_requestTitle }>{ Title }</span>
                            <span className={ styles.new_requestType } data-contentTypeName={ `${Requesttype}`.toLowerCase() }>{ Requesttype || "Request" }</span>
                        </div>
                        <div className={ styles.new_requestMetadataContainer }>
                            <div className={ styles.new_requestFieldContainer}>
                                <span>MP</span>
                                <span>{ MP ? MP.Label : "" }</span>
                            </div>
                            <div className={ styles.new_requestFieldContainer}>
                                <span>Party</span>
                                <span>{ Party ? Party.Label : "" }</span>
                            </div>
                            {
                                Budgetbaseline && (
                                    <div className={ styles.new_requestFieldContainer} style={{ gridColumn: "3"}}>
                                        <span>Budget baseline</span>
                                        <span>{ Budgetbaseline }</span>
                                    </div>
                                )
                            }
                            {
                                Created && (
                                    <div className={ styles.new_requestFieldContainer} style={{ gridColumn: "4"}}>
                                        <span>Created</span>
                                        <span>{ Created ? (new Date(Created)).toLocaleDateString("en-au") : "" }</span>
                                    </div>
                                )
                            }
                            {
                                Response_x0020_date && (
                                    <div className={ styles.new_requestFieldContainer} style={{ gridColumn: "5"}}>
                                        <span>Response date</span>
                                        <span>{ Response_x0020_date ? (new Date(Response_x0020_date)).toLocaleDateString("en-au") : "" }</span>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className={ styles.new_requestRhContainer }>
                        <span data-current-state={ `${Currentstate}`.toLowerCase() } className={ styles.new_requestState }>{ Currentstate }</span>
                        <div className={ styles.new_buttonContainer }>
                            { CanEdit === "Yes" && ( <a title='Edit form' onClick={ (event) => navigateToForm(event, FormLookupId) } ><FabricIcon iconName='DocumentEditRegular' />Edit</a> )}
                            
                            { CanHold === "Yes" && <a title='Place on hold' onClick={ () => openModalByKey("hold", [Id]) } ><FabricIcon iconName='PauseRegular' />Place on hold</a> }
                            { CanWithdraw === "Yes" && <a title='Withdraw' onClick={ () => openModalByKey("withdraw", [Id]) }><FabricIcon iconName='StopRegular' />Withdraw</a> }
                            {
                                Response_x0020_URL && (
                                    <a title='View response' href={ Response_x0020_URL } target="_blank" ><FabricIcon iconName='DocumentTableCheckmarkRegular' />View response</a>
                                )
                            }
                            { CanPublicRelease === "Yes" && <a title='Request public release' onClick={ () => openModalByKey("publicRelease", [Id]) }><FabricIcon iconName='ArrowUploadFilled' />Request public release</a> }
                            { CanUpdateResponse === "Yes" && <a title='Request updated response' onClick={ () => openModalByKey("update", [Id]) }><FabricIcon iconName='DocumentSyncRegular' />Request updated response</a> }
                        </div>
                    </div>
                </div>
            )
        })
    )
}

// export const NewRequestListItem = (item: any) => {
//     return (
//         <div>

//         </div>
//     )
// }