// extracted by mini-css-extract-plugin
export var bodyGrid = "PortalMultiCostingCalculationModal-module--body-grid--e98b3";
export var centerAlignedBody = "PortalMultiCostingCalculationModal-module--centerAlignedBody--a1636";
export var closeButton = "PortalMultiCostingCalculationModal-module--closeButton--f0065";
export var modalBody = "PortalMultiCostingCalculationModal-module--modalBody--3bb72";
export var modalContainer = "PortalMultiCostingCalculationModal-module--modalContainer--2215b";
export var modalFooter = "PortalMultiCostingCalculationModal-module--modalFooter--3484b";
export var modalTitle = "PortalMultiCostingCalculationModal-module--modalTitle--f8aad";
export var overlay = "PortalMultiCostingCalculationModal-module--overlay--85cdf";
export var sectionGrid = "PortalMultiCostingCalculationModal-module--sectionGrid--fc82b";
export var sectionGridAlignCenter = "PortalMultiCostingCalculationModal-module--sectionGridAlignCenter--ac45d";
export var selectedItemsList = "PortalMultiCostingCalculationModal-module--selectedItemsList--296f7";